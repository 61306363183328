import { render, staticRenderFns } from "./VideoEditing.vue?vue&type=template&id=1a5c648e&"
import script from "./VideoEditing.vue?vue&type=script&lang=js&"
export * from "./VideoEditing.vue?vue&type=script&lang=js&"
import style0 from "./VideoEditing.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

component.options.__file = "VideoEditing.vue"
export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VLayout } from 'vuetify/lib'
import { VResponsive } from 'vuetify/lib'
installComponents(component, {
  VLayout,
  VResponsive,
})
